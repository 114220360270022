var $ = require('jquery');

//.accordion2の中のp要素がクリックされたら
$('.header__innerNavList p').click(function(){

    //クリックされた.accordion2の中のp要素に隣接する.accordion2の中の.innerを開いたり閉じたりする。
    $(this).next('.header__innerNavList .second').slideToggle();

    //クリックされた.accordion2の中のp要素以外の.accordion2の中のp要素に隣接する.accordion2の中の.innerを閉じる
    $('.header__innerNavList p').not($(this)).next('.header__innerNavList .second').slideUp();

});